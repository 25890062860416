export const environment = {
  name: 'klara-test',
  production: false,
  firebase: {
    apiKey: 'AIzaSyAUZAUXgbome-ZuA8ZNxy1y5Z9zqB-HnCQ',
    authDomain: 'klara-epost-test-doc2epost.firebaseapp.com',
    projectId: 'klara-epost-test-doc2epost',
    storageBucket: 'klara-epost-test-doc2epost.appspot.com',
    messagingSenderId: '674858824309',
    appId: '1:674858824309:web:25474bb145e4e4984dbe3c',
    measurementId: 'G-RMZSQQ5KMQ',
  },
  keycloak: {
    domain: 'login-test.klara.tech',
    realmName: 'klara',
    clientId: 'smartsend',
    clientSecret: '76dea2b4-28f7-4578-adcf-23789e636082',
    redirectUri: 'https://smartsend-test.epost.dev',
  },
  ePostUrl: 'https://test.klara-epost.tech'
};
